import { createWebHistory, createRouter } from 'vue-router'
import Home from '@/views/Home.vue'
import Landingpage from '@/views/Landingpage.vue'
import Privacypolicy from '@/views/Privacypolicy.vue'
import Deleteaccount from '@/views/Deleteaccount.vue'
import NotFound from '@/views/error-page/NotFound.vue'
import SibakatDeleteAccount from '@/views/SibakatDeleteAccount.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/:uuid',
    name: 'landingpage',
    component: Landingpage,
  },
  {
    path: '/privacy-policy/',
    name: 'privacypolicy',
    component: Privacypolicy,
  },
  {
    path: '/request-account-deletion/',
    name: 'deleteaccount',
    component: Deleteaccount,
  },
  {
    path: '/sibakat-account-deletion/',
    name: 'sibakatdeleteaccount',
    component: SibakatDeleteAccount,
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: NotFound,
  },
]

const router = new createRouter({
  history: createWebHistory(),
  routes,
})

export default router
